import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './components/home/home.component';
import { EmpresaComponent } from './components/empresa/empresa.component';
import {GaleriaComponent} from './components/galeria/galeria.component';
import {ContactoComponent} from './components/contacto/contacto.component';
import {CommingsoonComponent} from './components/commingsoon/commingsoon.component';
import {PortafoliosComponent} from './components/portafolios/portafolios.component';
import {PublicacionesComponent} from './components/publicaciones/publicaciones.component';


const routes: Routes = [
  { path: 'home', component: HomeComponent },
  { path: '', component: HomeComponent },
  { path: 'empresa', component: EmpresaComponent },
  { path: 'galeria', component: GaleriaComponent },
  { path: 'contacto', component: ContactoComponent },
  { path: 'estaciones', component: PortafoliosComponent },
  { path: 'estanterias', component: PortafoliosComponent },
  { path: 'sillas', component: PortafoliosComponent },
  { path: 'mesas', component: PortafoliosComponent },
  { path: 'counters', component: PortafoliosComponent },
  { path: 'archivadores', component: PortafoliosComponent },
  { path: 'escritorios', component: PortafoliosComponent },
  // { path: 'credenzas', component: PortafoliosComponent },
  { path: 'closets', component: PortafoliosComponent },
  { path: 'cocinas', component: PortafoliosComponent },
  { path: 'gavetas', component: PortafoliosComponent },
  { path: 'divisiones', component: PortafoliosComponent },
  { path: 'cafeinternet', component: PortafoliosComponent },
  { path: 'commingsoon', component: CommingsoonComponent },
  { path: 'publicaciones', component: PublicacionesComponent },
  { path: '**', pathMatch: 'full', redirectTo: 'commingsoon' }
];

export const AppRoutingModule = RouterModule.forRoot(routes);
