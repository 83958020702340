import { Component, OnInit } from '@angular/core';
import { Contacto } from '../../interfaces/contacto';
import {EmailService} from '../../services/email.service';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-contacto',
  templateUrl: './contacto.component.html',
  styleUrls: ['./contacto.component.css']
})
export class ContactoComponent implements OnInit {

  public contacto: Contacto = {
    names :  '',
    email :  '',
    asunto : ''
  };
  sendMail;
  response;

  constructor(private emailService: EmailService) {
    this.sendMail = emailService;
  }

  ngOnInit() {
  }

  enviarContacto(): void {
    this.sendMail.sendMail(this.contacto).subscribe((data) => (this.response = data));
    Swal.fire({
      title : 'Datos enviados con éxito!',
      text: 'Muy pronto nos pondremos en contacto con usted. Gracias por escribirnos',
      type: 'success',
      confirmButtonText: 'Aceptar'
    });
    // console.log(this.response);
    // this.clearInputs();
  }

  clearInputs(): void {
    this.contacto = {
      names :  '',
      email :  '',
      asunto : ''
    };
  }

}
