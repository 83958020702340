import { Component, OnInit } from '@angular/core';
import { MfconfigService } from '../../services/mfconfig.service';

@Component({
  selector: 'app-empresa',
  templateUrl: './empresa.component.html',
  styleUrls: ['./empresa.component.css']
})
export class EmpresaComponent implements OnInit {

  config: any = {};

  constructor(private mFconfig: MfconfigService) {
    this.config = this.mFconfig.empresaData;
  }

  ngOnInit() {
  }

}
