import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class InstagramService {

  feed = null;

  // private urlTarget = 'https://www.instagram.com/graphql/query/';
  private urlTarget = 'http://localhost:3000/';

  constructor(private httpClient: HttpClient) { }

  getFeed(): Observable<any> {
    console.log('lanzando esto');
    // @ts-ignore
    const params = new HttpParams({
      // @ts-ignore
      query_id : '17888483320059182',
      variables : JSON.stringify({id: '7133246744', first: 20, after: null})
    });
    // return this.httpClient.get(this.urlTarget, { params: params});
    return this.httpClient.get(this.urlTarget);
  }
}
