import { Component, OnInit } from '@angular/core';
import {MfconfigService} from '../../services/mfconfig.service';

@Component({
  selector: 'app-commingsoon',
  templateUrl: './commingsoon.component.html',
  styleUrls: ['./commingsoon.component.css']
})
export class CommingsoonComponent implements OnInit {

  image: any = {};
  textCommingSoon: any;
  constructor(private mFconfig: MfconfigService) {
    this.image = this.mFconfig.mFConfig.urlImage;
    this.textCommingSoon = this.mFconfig.mFConfig.contentCommingSoon;
  }

  ngOnInit() {
  }

}
