import {Component, OnInit, ViewChild} from '@angular/core';
import {MfconfigService} from '../../services/mfconfig.service';
import { Router } from '@angular/router';
import {Gallery, GalleryItem, ImageItem} from '@ngx-gallery/core';
import {Lightbox} from '@ngx-gallery/lightbox';
import {FireserviceService} from '../../services/fireservice.service';



@Component({
  selector: 'app-portafolios',
  templateUrl: './portafolios.component.html',
  styleUrls: ['./portafolios.component.css']
})
export class PortafoliosComponent implements OnInit {
  paused = false;
  pauseOnHover = true;
  fireServ;
  images;
  modalIn: boolean;
  srcModal: string;
  currentUrl: string;
  URL = 'https://api.whatsapp.com/send?phone=+593959638071&text=Quiero%20m%C3%A1s%20informaci%C3%B3n%20sobre%20este%20articulo%20';

  constructor(
    private MfConfig: MfconfigService,
    private router: Router,
    private fireService: FireserviceService,
    public gallery: Gallery,
    public lightbox: Lightbox
  ) {
    this.fireServ = fireService;
    this.modalIn = false;
    this.currentUrl = this.router.url;
  }

  async ngOnInit() {
    this.images = await this.fireServ.getCollectionFire('gallery', 'portfolio')
      .then((res) => {
        switch (this.router.url) {
          case '/sillas':
            return res.sillas;
          case '/archivadores':
            return res.archivadores;
          case '/estaciones':
            return res.estaciones;
          case '/cafeinternet':
            return res.cafeinternet;
          case '/closets':
            return res.closets;
          case '/counters':
            return res.counters;
          case '/cocinas':
            return res.cocinas;
          case '/divisiones':
            return res.divisiones;
          case '/escritorios':
            return res.escritorios;
          case '/estanterias':
            return res.estanterias;
          case '/gavetas':
            return res.gavetas;
          case '/mesas':
            return res.mesas;
          default:
            return null;
        }
      });
  }
  showImg(e, src) {
    e.preventDefault();
    this.modalIn = true;
    this.srcModal = src;
  }
  hideModalIn(e) {
    e.preventDefault();
    this.modalIn = false;
  }
  callWpArticulo(url) {
    return this.URL + url;
  }
  callWpGo(url) {
    const myUrl = this.callWpArticulo(url);
    window.open(myUrl, '_blank');
  }
}
