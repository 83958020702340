import {Component, OnInit} from '@angular/core';
import {InstagramService} from '../../services/instagram.service';
import {environment} from '../../../environments/environment';

@Component({
  selector: 'app-publicaciones',
  templateUrl: './publicaciones.component.html',
  styleUrls: ['./publicaciones.component.css']
})
export class PublicacionesComponent implements OnInit {
  public posts = null;
  constructor(private iGService: InstagramService) { }

  ngOnInit() {
    // @ts-ignore
    const userFeed = new Instafeed({
      get: 'user',
      target: 'instafeed-container',
      resolution: 'standard_resolution',
      // tslint:disable-next-line:max-line-length
      template : `<div class='item_gallery col-12 col-sm-12 col-md-4 col-lg-3 animated fadeInUp clickIn' style="background: url('{{image}}') no-repeat; background-size: cover; height: 250px; background-position: center;"></div>`,
      accessToken: environment.Instafeed.accessToken,
      limit: 24,
    });
    userFeed.run();
  }

  mostrar() {
    console.log('hola!!!');
  }
}
