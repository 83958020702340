import { Component, OnInit } from '@angular/core';
import { MfconfigService } from '../../services/mfconfig.service';
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  providers: [NgbCarouselConfig]
})
export class HomeComponent implements OnInit {

  config: any = '';

  constructor(private MfConfig: MfconfigService, private ngConfig: NgbCarouselConfig) {
    this.config = MfConfig.getConfig();
    ngConfig.interval = 10000;
    ngConfig.wrap = false;
    ngConfig.keyboard = false;
    ngConfig.pauseOnHover = false;
  }

  ngOnInit() {
  }

}
