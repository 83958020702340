import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MfconfigService {

  public mFConfig: any = {
    nombre : 'Mobiliarios Famarsa',
    urlImage : '../assets/img/logo_Mobiliarios-famarsa.png',
    menu : [
      {'name': 'Inicio', 'link': '/home', 'sub': null},
      {'name': 'Empresa', 'link': '/empresa', 'sub': null},
      {'name': 'Catálogo', 'link': null, 'sub': [
          {'name': 'Estaciones', 'link': '/estaciones', 'sub': null},
          {'name': 'Estanterías', 'link': '/estanterias', 'sub': null},
          {'name': 'Sillas', 'link': '/sillas', 'sub': null},
          {'name': 'Mesas', 'link': '/mesas', 'sub': null},
          {'name': 'Counters', 'link': '/counters', 'sub': null},
          {'name': 'Archivadores', 'link': '/archivadores', 'sub': null},
          {'name': 'Mesas', 'link': '/mesas', 'sub': null},
          {'name': 'Escritorios', 'link': '/escritorios', 'sub': null},
          // {'name': 'Credenzas', 'link': '/credenzas', 'sub': null},
          {'name': 'Closets', 'link': '/closets', 'sub': null},
          {'name': 'Cocinas', 'link': '/cocinas', 'sub': null},
          {'name': 'Gavetas', 'link': '/gavetas', 'sub': null},
          {'name': 'Divisiones', 'link': '/divisiones', 'sub': null},
          {'name': 'Café Internet', 'link': '/cafeinternet', 'sub': null}
        ]},
      // {'name': 'Publicaciones', 'link': '/publicaciones', 'sub': null},
      {'name': 'Contacto', 'link': '/contacto', 'sub': null},
      // {'name': 'Galería', 'link': '/galeria', 'sub': null}
    ],
    slider: [
      {
        'titulo' : 'Bienvenidos a \n Mobiliarios Famarsa',
        'parrafo1' : 'Donde siempre queremos que te sientas cómodo.',
        'parrafo2' : 'Haz de tu oficina un lugar agradable',
        'src' : 'https://firebasestorage.googleapis.com/v0/b/mobiliariosfamarsaangular.appspot.com/o/slider6.jpg?alt=media&token=b70d05b5-6c6c-49b1-96cb-20b9a6547361',
        'link': '',
        'target': ''
      },
      {
        'titulo' : 'En Mobiliarios Famarsa',
        'parrafo1' : 'Trabajamos para darte lo mejor.',
        'parrafo2' : 'Siempre calidad al mejor precio !!',
        'src': 'https://firebasestorage.googleapis.com/v0/b/mobiliariosfamarsaangular.appspot.com/o/slider8.jpg?alt=media&token=c6b7c827-b500-403e-86fb-6f52c81291d6',
        'link': '',
        'target': ''
      },
      {
        'titulo' : 'Estaciones de trabajo',
        'parrafo1' : 'La más completa colección y variedad de estaciones de trabajo que desees.',
        'parrafo2' : '',
        'src': 'https://firebasestorage.googleapis.com/v0/b/mobiliariosfamarsaangular.appspot.com/o/slider1.jpg?alt=media&token=0e6345f8-3753-4b05-9314-4e4c9a05c191',
        'link': '#',
        'target': ''
      },
      {
        'titulo' : 'Sillas de espera',
        'parrafo1' : 'Tenemos grán variedad de sillas de espera y de consultorio.',
        'parrafo2' : 'Anímate a ver nuestro catálogo',
        'src': 'https://firebasestorage.googleapis.com/v0/b/mobiliariosfamarsaangular.appspot.com/o/slider7.jpg?alt=media&token=33178393-5716-4e45-bba4-d59bc83af2ac',
        'link': '#',
        'target': ''
      }
    ],
    subTitlehome: 'Muebles de Oficina y para Negocios',
    miniatures : [
      {'title': 'Estaciones de Trabajo', 'description': 'Pensadas y creadas a tu manera, para una mejor comodidad' , 'src': 'https://firebasestorage.googleapis.com/v0/b/mobiliariosfamarsaangular.appspot.com/o/miniatures%2F04_min-300x250.png?alt=media&token=15bb3ea1-e5e5-492e-8abd-d16205a4980b', 'enlace': 'estaciones'},
      {'title': 'Sillas giratorias y de espera', 'description': 'Diseño y comodidad de ultima generación' , 'src': 'https://firebasestorage.googleapis.com/v0/b/mobiliariosfamarsaangular.appspot.com/o/miniatures%2F04_min2-300x250.png?alt=media&token=9302c139-e059-42e8-a177-f07ca04ce9d1', 'enlace': '#'},
      {'title': 'Counters', 'description': 'Con todo el espacio necesario para llevar tu orden' , 'src': 'https://firebasestorage.googleapis.com/v0/b/mobiliariosfamarsaangular.appspot.com/o/miniatures%2F01_min-300x250.png?alt=media&token=95754258-1d7b-4f5a-98b4-d31ed3d11adb', 'enlace': '#'},
      {'title': 'Archivadores', 'description': 'La mejor forma de llevar registro de tus documentos' , 'src': 'https://firebasestorage.googleapis.com/v0/b/mobiliariosfamarsaangular.appspot.com/o/miniatures%2F02_minarc-300x250.png?alt=media&token=0973c7dd-31e5-4a4f-b04d-a1fe634bfaa9', 'enlace': '#'},
      {'title': 'Mesas', 'description': 'Amplias y con el mejor diseño' , 'src': 'https://firebasestorage.googleapis.com/v0/b/mobiliariosfamarsaangular.appspot.com/o/miniatures%2F04_minmensas-300x250.png?alt=media&token=7d33c831-b5e7-4ab7-b30f-6d4893afd1c6', 'enlace': '#'},
      {'title': 'Escritorios', 'description': 'Diseño y distribución de espacios' , 'src': 'https://firebasestorage.googleapis.com/v0/b/mobiliariosfamarsaangular.appspot.com/o/miniatures%2F11_min-1-300x250.png?alt=media&token=e5dc9709-c59e-4087-b821-50cca9f6ca7c', 'enlace': '#'},
      {'title': 'Estanterías', 'description': 'Espacios de soportes para cualquier elemento' , 'src': 'https://firebasestorage.googleapis.com/v0/b/mobiliariosfamarsaangular.appspot.com/o/miniatures%2F04_minestanterias-300x250.png?alt=media&token=5d696486-ced0-4dc0-aca7-87762a50d3ee', 'enlace': '#'},
      {'title': 'Credemzas', 'description': 'Mejor distribución de espacios para tus documentos' , 'src': 'https://firebasestorage.googleapis.com/v0/b/mobiliariosfamarsaangular.appspot.com/o/miniatures%2F03_min-300x250.png?alt=media&token=8a8dd566-0ada-4c09-8cb2-ba2129d1f37a', 'enlace': '#'},
      {'title': 'Closets y armarios', 'description': 'Amplios y con excelente distribución de espacios' , 'src': 'https://firebasestorage.googleapis.com/v0/b/mobiliariosfamarsaangular.appspot.com/o/miniatures%2F01_mincloset-300x250.png?alt=media&token=6531616b-d33d-428b-b9a2-c622fd70bef1', 'enlace': '#'},
      {'title': 'Cocinas integrales', 'description': 'Diseño y tamaño a tu manera con excelentes materiales' , 'src': 'https://firebasestorage.googleapis.com/v0/b/mobiliariosfamarsaangular.appspot.com/o/miniatures%2F03_mincocinas-300x250.png?alt=media&token=ef8425bb-eecb-406c-932c-8efc2d1b68dd', 'enlace': '#'},
      {'title': 'Divisiones y cubículos', 'description': 'Distribución de espacios para elementos de computo y documentos' , 'src': 'https://firebasestorage.googleapis.com/v0/b/mobiliariosfamarsaangular.appspot.com/o/miniatures%2F09_min-300x250.png?alt=media&token=e9f82d77-05e6-4b70-a39f-91b4b5ba3f1e', 'enlace': '#'},
      {'title': 'Muebles para café internet', 'description': 'La mejor forma de organizar el espacio de tu negocio con excelente diseño y material' , 'src': 'https://firebasestorage.googleapis.com/v0/b/mobiliariosfamarsaangular.appspot.com/o/miniatures%2F07_min-300x250.png?alt=media&token=ec544bb6-0c91-48fa-8c56-e0e66459acfb', 'enlace': '#'}
    ],
    contentDescrition: {
      title: 'Mobiliarios famarsa',
      texto1: 'Todo lo relacionado con Muebles de oficina, Somos fabricantes de los mejores artículos con la mejor calidad, estamos ubicados en Lago agrio, Sucumbios, Ecuador.' +
        ' Producción, suministro e instalación de sistemas modulares para oficina. A través de desarrollos propios damos respuesta a diversos requerimientos espaciales ajustados a los presupuestos de nuestros clientes, involucrando conceptos tecnológicos universales apoyados en un equipo multidisciplinario de desarrollo de producto y arquitectura. Contamos con la infraestructura necesaria para suministrar y si se requiere instalar proyectos en toda la región amazónica de ecuador.',
      texto2: 'Durante toda nuestra historia nos hemos categorizado como una empresa que provee productos de altísima calidad. Es por esto que uno de nuestros servicios es la comercialización de partes y componentes de mobiliario y sillas a empresas, proveedores y clientes. Teniendo en cuenta nuestra tecnología podemos producir grandes cantidades de partes plásticas de diferentes colores para suplir su proyecto del mejor producto con las características indicadas'
    },
    contentCommingSoon: {
      title : 'Espera esta sección muy pronto',
      textParrafo : 'Actualmente estamos trabajano para habilitar estos espacios y poder ofrecerte todo los productos de Mobiliarios Famarsa y que puedas' +
          ' tener una mejor experiencia en nuestro sitio web. Es por eso que estamos tomanonos un tiempo para ti. Mientras tanto te invitamos' +
          'a visitar nuestros perfiles en la redes sociales: ',
      facebook : 'https://www.facebook.com/mobiliariofamarsa/',
      instagram : 'https://www.instagram.com/mobiliarios_famarsa/'
    },
    map: {
      lat: 0.085028,
      lng: -76.904310,
      zoom: 15
    },
    footer: {
      col1: {
        title: 'Dirección',
        items: [
          {'title': 'Dir', 'value' : 'Via Quito Km 2 1/2'},
          {'title': 'Barrio', 'value' : 'Oro negro Diag Roman Hermanos'},
          {'title': 'Lago agrio', 'value' : 'Sucumbios - Ecuador'}
        ]
      },
      col2: {
        title: 'Contacto',
        items: [
          {'title': 'Ing', 'value' : 'Fabriciano Martínez Sánchez'},
          {'title': 'Tels', 'value' : '0959638071 - 0992506911'},
          {'title': 'Sec', 'value' : 'General: Odalinda Muentes'},
          {'title': 'Tel', 'value' : '0992114751'}
        ]
      }
    },
    footerBajo: {
      col1: {
        copy: 'Copyright © 2014 -',
        page: 'mobiliariosfamarsa.com',
        right: 'All rights reserved.'
      },
      col2: {
        createdBy: 'Created By Eric Js Mtz',
        link: '#'
      }
    }
  };

  public empresaData: any = {
    title: 'Mobiliarios Famarsa',
    subTitle: 'Nuestra Empresa',
    img1: 'https://firebasestorage.googleapis.com/v0/b/mobiliariosfamarsaangular.appspot.com/o/gallery%2FWhatsApp%20Image%202019-05-20%20at%205.20.42%20PM.jpeg?alt=media&token=43b8f96e-2e85-4cde-836e-b9e9b4c8a2af',
    img2: 'https://firebasestorage.googleapis.com/v0/b/mobiliariosfamarsaangular.appspot.com/o/gallery%2FWhatsApp%20Image%202019-05-20%20at%205.20.42%20PM.jpeg?alt=media&token=43b8f96e-2e85-4cde-836e-b9e9b4c8a2af',
    content: [
      {'title': '¿Quienes Somos?', 'parrafo': 'Somo una empresa fabricantes de muebles para toda clase de sitios como empresas, hogares, negocios y establecimientos públicos, Somos Mobiliarios Famarsa.'},
      {'title': 'Misión', 'parrafo': 'Mobiliarios famarsa Somos una empresa nueva con innovación en la mercadotecnia que busca el más alto grado de satisfacción del cliente al ofrecer excelentes servicio y soluciones para mobiliarios de oficinas y colectividad, basándonos en criterios funcionales, de diseño, calidad y oportunos tiempos de entrega. Siempre tomando en cuenta las tendencias del mercado y adaptándonos a las necesidades de nuestros cliente'},
      {'title': 'Visión', 'parrafo': 'Mobiliarios famarsa Nos destacamos en el mercado del Oriente ecuatoriano por cumplir con los más exigentes requerimientos de calidad al elaborar mobiliarios de oficina y colectividad con tecnología de mayor precisión e innovación, lo cual nos permitirá expandir nuestros mercado a paises vecinos.'},
    ],
    content2: [
      {'title': 'Valores', 'value': 'Valores'},
      {'title': 'Calidad', 'value': 'Calidad'},
      {'title': 'Honestidad', 'value': 'Honestidad'},
      {'title': 'Compromiso', 'value': 'Compromiso'},
      {'title': 'Creatividad e Innovación', 'value': 'Creatividad e Innovación'},
      {'title': 'Eficiencia', 'value': 'Eficiencia'},
      {'title': 'Capital Humano', 'value': 'Capital Humano'}
    ],
    content3: {
      title: 'Ubicación',
      map: {
        lat: 0.085028,
        lng: -76.904310,
        zoom: 15
      }
    }
  };

  public galeriaData: object = {
    title: 'Galería de Imágenes',
    content: [
      {'title': 'img1', 'src': 'assets/img/gallery/gallery_01.jpg'},
      {'title': 'img2', 'src': 'assets/img/gallery/gallery_02.jpg'},
      {'title': 'img3', 'src': 'assets/img/gallery/gallery_03.jpg'},
      {'title': 'img4', 'src': 'assets/img/gallery/gallery_04.jpg'},
      {'title': 'img5', 'src': 'assets/img/gallery/gallery_05.jpg'},
      {'title': 'img6', 'src': 'assets/img/gallery/gallery_06.jpg'},
      {'title': 'img7', 'src': 'assets/img/gallery/gallery_07.jpg'},
      {'title': 'img8', 'src': 'assets/img/gallery/gallery_08.jpg'},
      {'title': 'img9', 'src': 'assets/img/gallery/gallery_09.jpeg'},
      {'title': 'img10', 'src': 'assets/img/gallery/gallery_10.jpeg'},
      {'title': 'img11', 'src': 'assets/img/gallery/gallery_11.jpeg'},
      {'title': 'img12', 'src': 'assets/img/gallery/gallery_12.jpeg'},
      {'title': 'img13', 'src': 'assets/img/gallery/gallery_13.jpeg'}

    ]
  };

  public estaciones: any = [62, 83, 466, 965, 982, 1043, 738].map((n) => `https://picsum.photos/id/${n}/900/500`);

  constructor() {

  }

  getConfig() {
    return this.mFConfig;
  }
  getEmpresaData() {
    return this.empresaData;
  }
  getPortfolio(type) {
    let response: object;
    switch (type) {
      case '/estaciones':
        response = this.estaciones;
        break;
      case '/sillas':
        response = this.estaciones;
        break;
      case '/counters':
        response = this.estaciones;
        break;
      case '/archivadores':
        response = this.estaciones;
        break;
      default:
        break;
    }
    return response;
  }
}
