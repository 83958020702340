 import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';


import { AppComponent } from './app.component';

// NgBootstrap

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HeaderComponent } from './components/shared/header/header.component';
import { FooterComponent } from './components/shared/footer/footer.component';
import { HomeComponent } from './components/home/home.component';
import { AppRoutingModule } from './app-routing.module';
import { EmpresaComponent } from './components/empresa/empresa.component';
import { MfconfigService } from './services/mfconfig.service';
import { MapComponent } from './components/home/map/map.component';
import { AgmCoreModule } from '@agm/core';
import { GaleriaComponent } from './components/galeria/galeria.component';
import { GalleryModule } from '@ngx-gallery/core';
import { LightboxModule} from '@ngx-gallery/lightbox';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ContactoComponent } from './components/contacto/contacto.component';
import { FormsModule } from '@angular/forms';
import { CommingsoonComponent } from './components/commingsoon/commingsoon.component';
import { HttpClientModule } from '@angular/common/http';
import { ChatsComponent } from './components/shared/chats/chats.component';
import { PortafoliosComponent } from './components/portafolios/portafolios.component';
import { PublicacionesComponent } from './components/publicaciones/publicaciones.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    EmpresaComponent,
    MapComponent,
    GaleriaComponent,
    ContactoComponent,
    CommingsoonComponent,
    ChatsComponent,
    PortafoliosComponent,
    PublicacionesComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    NgbModule,
    AppRoutingModule,
    AgmCoreModule.forRoot({apiKey: 'AIzaSyA5mjCwx1TRLuBAjwQw84WE6h5ErSe7Uj8'}),
    GalleryModule,
    LightboxModule,
    FormsModule,
    HttpClientModule
  ],
  providers: [
    MfconfigService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
